<template>
    <ContentField>
        啊哦，走丢咯
    </ContentField>
</template>

<script>
import ContentField from "@/components/ContentField.vue"

export default{
    components:{
        ContentField
    }
}   
</script>

<style scoped>

</style>